* {
    font-family: "Titillium Web", sans-serif;
}

@media (min-width: 1201px) {
   #App {
        display: block;
        clear: both;
    }

    #body {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }

    #Navbar {
        position: sticky;
        top: 0;
        width: 100vw;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        height: 60px;
        background-color: white;


        div {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            
            img {
                height: 40px;
                padding: 30px;
            }
            
            a {
                height: auto;
                font-weight: bold;
                text-decoration: none;
                padding: 10px;
                color: black;
                border-left: 1px rgb(0, 0, 0) solid;
            }

            .enkr {
                border-radius: 25%;
                padding:0;
                margin: 0 10px;
                border: black 1px solid;
                width: 30px;
                height: 30px;
            }
        }
        #divFirstNav {
            width: 80%;
        }
    }

    #Home {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../public/images/bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 80px;

        #homeContent {
            background-color: rgba(255, 255, 255, .8);
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;
            align-items: center;
            padding: 30px;
            width: 70%;
            border-radius: 20px;

            img {
                width:30%;
                border: 2px black solid;
                padding: 10px;
            }
        
            .homeText {
                width: 60%;

                h2 {
                    font-size: 35px;
                    padding: 0;
                    margin: 0;
                }
        
                p {
                    font-size: 20px;
                    padding: 0;
                    margin: 0;
                }

                h4 {
                    font-size: 25px;
                    padding: 0;
                    margin:0;
                    margin-top: 10px;
                }
            }
        }

    }

    #Services {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 90%;
        padding: 20px 0;

        h2 {
            font-size: 35px;
            border-bottom: 2px black solid;
            padding: 0 30px;
        }

        span {
            font-size: 20px;
        }

        #servicesBox {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            width: 100%;
            gap: 10px;
            margin: 20px 0px;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                aspect-ratio: 1/1;
                width: 150px;
                border: 2px black solid;
                border-radius: 50%;

                span {
                    font-size: 16px;
                    padding: 0 2px;
                    color: white;
                    font-weight: bold;
                    background-color: black;
                }
            }

            #electrical {
                background-image: url("../public/images/electrical.jpg");
                background-size: cover;
            }
            #plumbing{
                background-image: url("../public/images/plumbing.jpg");
                background-size: cover;
            }
            #hvac{
                background-image: url("../public/images/hvac.jpg");
                background-size: cover;
            }
            #repairs{
                background-image: url("../public/images/repair.jpg");
                background-size: cover;
            }
            #flooring{
                background-image: url("../public/images/flooring.jpg");
                background-size: cover;
            }
            #basement{
                background-image: url("../public/images/wall.jpg");
                background-size: cover;
            }
            #kitchen{
                background-image: url("../public/images/kitchen.jpg");
                background-size: cover;
            }
            #bathrooms{
                background-image: url("../public/images/bathroom.jpg");
                background-size: cover;
            }
            #decks{
                background-image: url("../public/images/deck.jpg");
                background-size: cover;
            }
            #more{
                background-image: url("../public/images/more.jpg");
                background-size: cover;
            }
        }
    }

    #Gallery {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 90%;
        padding: 20px 5%;
        background-color: rgb(48, 48, 48);
        color: white;

        h2 {
            font-size: 35px;
            border-bottom: 2px rgb(255, 255, 255) solid;
            padding: 0 30px;
        }

        .albums {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            align-items: center;
            gap: 5px;
            padding: 3px;
            margin: 3px;
        
            .Album {
                width: 20%;
                cursor: pointer;

                .thumbnail {
                    width: 100%;
                    border: 2px rgb(255, 255, 255) solid;
                }

                .overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.8);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;

                    .overlay-content {
                        position: relative;
                        display: flex;
                        flex-flow: column nowrap;
                        align-items: center;

                        .enlarged-image {
                            max-width: 80vw;
                            max-height: 80vh;
                        }
                            
                        .close-button {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            background: white;
                            padding: 8px;
                            border-radius: 20px;
                            color: rgb(0, 0, 0);
                            font-size: 20px;
                            cursor: pointer;
                        }

                        .imgSet {
                            width: 100%;
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: center;

                            img {
                                margin: 5px;
                                border: white 2px solid;
                                width: 80px;
                                height: 80px;
                            }
                        }
                    }
                }
            }
        }
    }

    #Contact {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 90%;
        padding: 20px 0;

        h2 {
            font-size: 35px;
            border-bottom: 2px black solid;
            padding: 0 30px;
        }
        
        >div {
            padding: 0px 0px 50px 0px;
            font-size: 25px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            div {
                width: auto;
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                padding-left: 50px;
            }

            img {
                border-radius: 50%;
                width: 30%;
                border: 2px black solid;
            }
        }
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    #App {
        display: block;
        clear: both;
    }

    #body {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }

    #Navbar {
        position: sticky;
        top: 0;
        width: 100vw;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        height: 60px;
        background-color: white;


        div {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            padding:0;
            
            #logoNav {
                height: 40px;
                padding: 30px;
            }
            
            a {
                height: auto;
                font-weight: bold;
                text-decoration: none;
                padding: 10px;
                color: black;
                border-left: 1px rgb(0, 0, 0) solid;
            }

            .enkr {
                border-radius: 25%;
                padding:0;
                margin: 0 10px;
                border: black 1px solid;
                width: 25px;
            }

            #contactNav {
                width: 100%;
            }
        }
        #divFirstNav {
            width: 80%;
        }
    }

    #Home {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../public/images/bg.png");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 80px;

        #homeContent {
            background-color: rgba(255, 255, 255, .8);
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-evenly;
            align-items: center;
            padding: 30px;
            width: 70%;
            border-radius: 20px;

            img {
                width:30%;
                border: 2px black solid;
                padding: 10px;
            }
        
            .homeText {
                width: 60%;

                h2 {
                    font-size: 20px;
                    padding: 0;
                    margin: 0;
                }
        
                p {
                    font-size: 15px;
                    padding: 0;
                    margin: 0;
                }

                h4 {
                    font-size: 18px;
                    padding: 0;
                    margin: 0;
                    margin-top: 10px;
                }
            }
        }

    }

    #Services {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 90%;
        padding: 20px 0;

        h2 {
            font-size: 30px;
            border-bottom: 2px black solid;
            padding: 0 30px;
        }

        span {
            font-size: 18px;
        }

        #servicesBox {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            width: 100%;
            gap: 10px;
            margin: 20px 0px;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                aspect-ratio: 1/1;
                width: 150px;
                border: 2px black solid;
                border-radius: 50%;

                span {
                    font-size: 16px;
                    padding: 0 2px;
                    color: white;
                    font-weight: bold;
                    background-color: black;
                }
            }

            #electrical {
                background-image: url("../public/images/electrical.jpg");
                background-size: cover;
            }
            #plumbing{
                background-image: url("../public/images/plumbing.jpg");
                background-size: cover;
            }
            #hvac{
                background-image: url("../public/images/hvac.jpg");
                background-size: cover;
            }
            #repairs{
                background-image: url("../public/images/repair.jpg");
                background-size: cover;
            }
            #flooring{
                background-image: url("../public/images/flooring.jpg");
                background-size: cover;
            }
            #basement{
                background-image: url("../public/images/wall.jpg");
                background-size: cover;
            }
            #kitchen{
                background-image: url("../public/images/kitchen.jpg");
                background-size: cover;
            }
            #bathrooms{
                background-image: url("../public/images/bathroom.jpg");
                background-size: cover;
            }
            #decks{
                background-image: url("../public/images/deck.jpg");
                background-size: cover;
            }
            #more{
                background-image: url("../public/images/more.jpg");
                background-size: cover;
            }
        }
    }

    #Gallery {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 90%;
        padding: 20px 5%;
        background-color: rgb(48, 48, 48);
        color: white;

        h2 {
            font-size: 30px;
            border-bottom: 2px rgb(255, 255, 255) solid;
            padding: 0 30px;
        }

        .albums {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            align-items: center;
            gap: 5px;
            padding: 3px;
            margin: 3px;
        
            .Album {
                width: 20%;
                cursor: pointer;

                .thumbnail {
                    width: 100%;
                    border: 2px rgb(255, 255, 255) solid;
                }

                .overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.8);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;

                    .overlay-content {
                        position: relative;
                        display: flex;
                        flex-flow: column nowrap;
                        align-items: center;

                        .enlarged-image {
                            max-width: 80vw;
                            max-height: 80vh;
                        }
                            
                        .close-button {
                            position: absolute;
                            top: 10px;
                            right: 10px;
                            background: white;
                            padding: 8px;
                            border-radius: 20px;
                            color: rgb(0, 0, 0);
                            font-size: 20px;
                            cursor: pointer;
                        }

                        .imgSet {
                            width: 100%;
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: center;

                            img {
                                margin: 5px;
                                border: white 2px solid;
                                width: 80px;
                                height: 80px;
                            }
                        }
                    }
                }
            }
        }
    }

    #Contact {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 90%;
        padding: 20px 0;

        h2 {
            font-size: 30px;
            border-bottom: 2px black solid;
            padding: 0 30px;
        }
        
        >div {
            padding: 0px 0px 50px 0px;
            font-size: 20px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            div {
                width: auto;
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
                padding-left: 50px;
            }

            img {
                border-radius: 50%;
                width: 25%;
                border: 2px black solid;
            }
        }
    }
}

@media (max-width: 767px) {
    #App {
        display: block;
        clear: both;
    }

    #body {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
    }

    #Navbar {
        position: sticky;
        top: 0;
        width: 100vw;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        height: 45px;
        background-color: white;
        border-bottom: 2px black solid;

        div {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: flex-start;
            padding:0;
            
            #logoNav {
                width: 135px;
                padding: 3px;
            }
            
            a {
                font-weight: bold;
                text-decoration: none;
                padding: 0px 5px;
                color: black;
                font-size: 13px;
            }

            .enkr {
                border-radius: 25%;
                padding:0;
                margin: 0 10px;
                border: black 1px solid;
                width: 18px;
            }

            #contactNav {
                width: 100%;
            }
            #homeNav {
                display: none;
            }
        }
        #divFirstNav {
            width: 80%;
        }
    }

    #Home {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url("../public/images/bg.png");
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 10px;

        #homeContent {
            background-color: rgba(255, 255, 255, .8);
            display: flex;
            flex-flow: column nowrap;
            justify-content: space-evenly;
            align-items: center;
            padding: 20px;
            width: 80%;
            border-radius: 20px;

            img {
                width: 60px;
                height: 60px;
                border: 1px black solid;
                aspect-ratio: 1/1;
                padding: 5px;
            }
        
            .homeText {
                width: 100%;

                h2 {
                    font-size: 20px;
                    padding-top: 5px;
                    margin: 0;
                }

                h4 {
                    font-size: 16px;
                    padding: 0;
                    margin: 0;
                    margin-top: 5px;
                }

                p {
                    font-size: 14px;
                    padding: 0;
                    margin: 0;
                }
            }
        }

    }

    #Services {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 90%;
        padding: 0px 0;

        h2 {
            font-size: 20px;
            border-bottom: 2px black solid;
            padding: 0 30px;
        }

        span {
            font-size: 14px;
        }

        #servicesBox {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            width: 100%;
            gap: 5px;
            margin: 15px 0px;

            div {
                display: flex;
                justify-content: center;
                align-items: center;
                aspect-ratio: 1/1;
                width: 95px;
                border: 2px black solid;
                border-radius: 50%;

                span {
                    font-size: 14px;
                    padding: 0 2px;
                    color: white;
                    font-weight: bold;
                    background-color: black;
                }
            }

            #electrical {
                background-image: url("../public/images/electrical.jpg");
                background-size: cover;
            }
            #plumbing{
                background-image: url("../public/images/plumbing.jpg");
                background-size: cover;
            }
            #hvac{
                background-image: url("../public/images/hvac.jpg");
                background-size: cover;
            }
            #repairs{
                background-image: url("../public/images/repair.jpg");
                background-size: cover;
            }
            #flooring{
                background-image: url("../public/images/flooring.jpg");
                background-size: cover;
            }
            #basement{
                background-image: url("../public/images/wall.jpg");
                background-size: cover;
            }
            #kitchen{
                background-image: url("../public/images/kitchen.jpg");
                background-size: cover;
            }
            #bathrooms{
                background-image: url("../public/images/bathroom.jpg");
                background-size: cover;
            }
            #decks{
                background-image: url("../public/images/deck.jpg");
                background-size: cover;
            }
            #more{
                background-image: url("../public/images/more.jpg");
                background-size: cover;
            }
        }
    }

    #Gallery {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 90%;
        padding: 0px 5%;
        padding-bottom: 20px;
        background-color: rgb(48, 48, 48);
        color: white;

        h2 {
            font-size: 20px;
            border-bottom: 2px white solid;
            padding: 0 30px;
        }

        .albums {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-around;
            align-items: center;
            gap: 5px;
            padding: 3px;
            margin: 3px;
        
            .Album {
                width: 30%;
                cursor: pointer;

                .thumbnail {
                    width: 100%;
                    border: 2px rgb(255, 255, 255) solid;
                }

                .overlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100vw;
                    height: 100vh;
                    background: rgba(0, 0, 0, 0.8);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 1000;

                    .overlay-content {
                        position: relative;
                        display: flex;
                        flex-flow: column nowrap;
                        align-items: center;

                        .enlarged-image {
                            max-width: 90vw;
                            max-height: 90vh;
                        }
                            
                        .close-button {
                            position: fixed;
                            top: 30px;
                            right: 20px;
                            background: white;
                            padding: 6px;
                            border-radius: 20px;
                            color: rgb(0, 0, 0);
                            font-size: 15px;
                            cursor: pointer;
                        }

                        .imgSet {
                            width: 100%;
                            display: flex;
                            flex-flow: row wrap;
                            justify-content: center;

                            img {
                                margin: 5px;
                                border: white 2px solid;
                                width: 50px;
                                height: 50px;
                            }
                        }
                    }
                }
            }
        }
    }

    #Contact {
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        width: 90%;
        padding: 0px 0;

        h2 {
            font-size: 20px;
            border-bottom: 2px black solid;
            padding: 0 30px;
        }
        
        >div {
            padding: 0px 0px 50px 0px;
            font-size: 16px;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            gap: 5px;

            div {
                width: auto;
                display: flex;
                flex-flow: column nowrap;
                align-items: center;
            }

            img {
                border-radius: 50%;
                width: 40%;
                border: 2px black solid;
            }
        }
    }
}